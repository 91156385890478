import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { Layout } from "../../components/Layout";

export const NotFound = (props: any) => {
  return (
    <Layout history={props.history}>
      <Card>
        <CardContent>
          <CardHeader title="404 Page Not Found"></CardHeader>
          <Typography>The page you looking for cannot be found</Typography>
        </CardContent>
      </Card>
    </Layout>
  );
};

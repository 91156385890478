import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import React from "react";
import { Layout } from "../../components/Layout";
import { useStores } from "../../contexts";
import EditIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { Loading } from "../../components/Loading";
import { YachtStatus, Broker, Cost } from "../../stores/app.store";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { observer } from "mobx-react";
import { NumberFormatCustom } from "../../components/NumberFormatCustom";
import { AutoCompleteInput } from "../../components/AutoCompleteInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    actionBar: {
      position: "fixed",
      right: 15,
      bottom: 15,
      zIndex: 1000,
    },
    actionBarButton: {
      margin: "0 5px",
    },
  })
);

export const SalesEditPage = observer((props: any) => {
  const { appStore } = useStores();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);

  const handleNumberInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): number | undefined => {
    return event.target.value ? +event.target.value : undefined;
  };

  const fetchSale = async (id: any) => {
    await appStore.fetchSale(id);
    setLoading(false);
  };

  React.useEffect(() => {
    const { id } = props.match.params;

    window.scrollTo(0, 0);

    if (id) {
      fetchSale(id);
    } else {
      appStore.createSale();
      setLoading(false);
    }

    appStore.setMenu("sales.list");
  }, [props.match.params, appStore]);

  const onSaveClicked = async () => {
    // setLoading(true);
    let result: any = {};

    if (appStore.sales.form.id) {
      result = await appStore.updateSale();
    } else {
      result = await appStore.saveSale();
    }

    if (result) {
      // show popup
      setShowAlert(true);
      //props.history.push("/sales");
    }
  };

  const onDeleteClicked = () => {
    setShowDeleteAlert(true);
  };

  const onCancelClicked = () => {
    props.history.push("/sales");
  };

  if (loading)
    return (
      <Layout history={props.history}>
        <Loading />
      </Layout>
    );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Layout history={props.history}>
        <div className={classes.actionBar}>
          <Fab className={classes.actionBarButton} onClick={onCancelClicked}>
            <CancelIcon />
          </Fab>
          {appStore.sales.form.id && (
            <Fab
              className={classes.actionBarButton}
              onClick={onDeleteClicked}
              color="secondary"
            >
              <DeleteIcon />
            </Fab>
          )}
          <Fab
            color="primary"
            className={classes.actionBarButton}
            onClick={onSaveClicked}
          >
            <EditIcon />
          </Fab>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Yacht Information"></CardHeader>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        label="Status"
                        onChange={(e) =>
                          (appStore.sales.form.status = e.target
                            .value as YachtStatus)
                        }
                        value={appStore.sales.form.status}
                      >
                        <MenuItem>None</MenuItem>
                        <MenuItem value="New">New</MenuItem>
                        <MenuItem value="PreOwned">Pre-Owned</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Hull Number"
                        onChange={(e) =>
                          (appStore.sales.form.hullnumber = e.target
                            .value as string)
                        }
                        value={appStore.sales.form.hullnumber}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Vessel Name"
                        onChange={(e) =>
                          (appStore.sales.form.name = e.target.value as string)
                        }
                        value={appStore.sales.form.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Brand"
                        onChange={(e) =>
                          (appStore.sales.form.brand = e.target.value as string)
                        }
                        value={appStore.sales.form.brand}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Model"
                        onChange={(e) =>
                          (appStore.sales.form.model = e.target.value as string)
                        }
                        value={appStore.sales.form.model}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Year"
                        onChange={(e) =>
                          (appStore.sales.form.year = e.target.value as string)
                        }
                        value={appStore.sales.form.year}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="length">Length (m)</InputLabel>
                      <Input
                        id="length"
                        onChange={(e: any) =>
                          (appStore.sales.form.yacht_length = e.target.value)
                        }
                        value={appStore.sales.form.yacht_length}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="listprice">
                        Purchase Price
                      </InputLabel>
                      <Input
                        id="purchasePrice"
                        disabled={appStore.sales.form.status?.toString() == "PreOwned"}
                        onChange={(e: any) =>
                          (appStore.sales.form.purchasePrice =
                            handleNumberInput(e))
                        }
                        value={appStore.sales.form.purchasePrice}
                        inputComponent={NumberFormatCustom as any}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="listprice">List Price</InputLabel>
                      <Input
                        id="listprice"
                        onChange={(e: any) =>
                          (appStore.sales.form.listPrice = handleNumberInput(e))
                        }
                        value={appStore.sales.form.listPrice}
                        inputComponent={NumberFormatCustom as any}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="soldprice">Sold Price</InputLabel>
                      <Input
                        id="soldprice"
                        onChange={(e: any) =>
                          (appStore.sales.form.soldPrice = handleNumberInput(e))
                        }
                        value={appStore.sales.form.soldPrice}
                        inputComponent={NumberFormatCustom as any}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="totalcommissionrate">
                        Total Commission Rate
                      </InputLabel>
                      <Input
                        id="totalcommissionrate"
                        disabled={
                          appStore.sales.form.status?.toString() == "New"
                        }
                        type="number"
                        onChange={(e: any) =>
                          (appStore.sales.form.totalCommission =
                            handleNumberInput(e))
                        }
                        onKeyUp={(e) => {
                          if (appStore.sales.form.totalCommission === 0) {
                            appStore.sales.form.totalCommissionAmount =
                              undefined;
                            return;
                          }

                          if (
                            appStore.sales.form.soldPrice &&
                            appStore.sales.form.totalCommission
                          ) {
                            appStore.sales.form.totalCommissionAmount = +(
                              (+appStore.sales.form.soldPrice *
                                +appStore.sales.form.totalCommission) /
                              100
                            ).toFixed(0);
                          } else {
                            appStore.sales.form.totalCommissionAmount = 0;
                          }
                        }}
                        value={appStore.sales.form.totalCommission}
                        startAdornment="%"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="totalcommissionprice">
                        Total Commission Price
                      </InputLabel>
                      <Input
                        id="totalcommissionprice"
                        disabled={appStore.sales.form.status?.toString() == "New"}
                        onChange={(e: any) =>
                          (appStore.sales.form.totalCommissionAmount =
                            handleNumberInput(e))
                        }
                        onKeyUp={(e) => {
                          if (appStore.sales.form.totalCommissionAmount === 0) {
                            appStore.sales.form.totalCommission = 0;
                            return;
                          }

                          if (
                            appStore.sales.form.soldPrice &&
                            appStore.sales.form.totalCommissionAmount
                          ) {
                            appStore.sales.form.totalCommission = +(
                              (+appStore.sales.form.totalCommissionAmount /
                                +appStore.sales.form.soldPrice) *
                              100
                            ).toFixed(2);
                          } else {
                            appStore.sales.form.totalCommission = undefined;
                          }
                        }}
                        value={appStore.sales.form.totalCommissionAmount}
                        inputComponent={NumberFormatCustom as any}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        label="Close Date"
                        value={appStore.sales.form.date}
                        onChange={(e: MaterialUiPickersDate, val: any) => {
                          appStore.sales.form.date = new Date(val);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={4}>
                    <AutoCompleteInput
                      label="Brand"
                      endpoint="/builders"
                      onChange={(e) => (appStore.sales.form.brand = e)}
                      value={appStore.sales.form.brand}
                    />
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={6}>
            <Card>
              <CardHeader title="Buyer Information"></CardHeader>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Full Name"
                        onChange={(e) =>
                          (appStore.sales.form.buyerName = e.target
                            .value as string)
                        }
                        value={appStore.sales.form.buyerName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Email"
                        onChange={(e) =>
                          (appStore.sales.form.buyerEmail = e.target
                            .value as string)
                        }
                        value={appStore.sales.form.buyerEmail}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Phone"
                        onChange={(e) =>
                          (appStore.sales.form.buyerPhone = e.target
                            .value as string)
                        }
                        value={appStore.sales.form.buyerPhone}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={6}>
            <Card>
              <CardHeader title="Seller Information"></CardHeader>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Full Name"
                        onChange={(e) =>
                          (appStore.sales.form.sellerName = e.target
                            .value as string)
                        }
                        value={appStore.sales.form.sellerName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Email"
                        onChange={(e) =>
                          (appStore.sales.form.sellerEmail = e.target
                            .value as string)
                        }
                        value={appStore.sales.form.sellerEmail}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Phone"
                        onChange={(e) =>
                          (appStore.sales.form.sellerPhone = e.target
                            .value as string)
                        }
                        value={appStore.sales.form.sellerPhone}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={12}>
            <Card>
              <CardHeader title={`Brokers`}></CardHeader>
              <Divider />
              <CardContent>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => appStore.addBroker()}
                >
                  Add Broker
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {appStore.sales.form.brokers.map((broker: Broker, i: number) => (
            <Grid item md={4} key={i}>
              <Card style={{ marginTop: 15 }}>
                <CardHeader title={`Broker Company ${i + 1}`}></CardHeader>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Button onClick={() => appStore.removeBroker(i)}>
                        Remove
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Company Name"
                          onChange={(e) =>
                            (broker.company = e.target.value as string)
                          }
                          value={broker.company}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Responsible Name"
                          onChange={(e) =>
                            (broker.name = e.target.value as string)
                          }
                          value={broker.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="CommissionRate">
                          Commission Rate
                        </InputLabel>
                        <Input
                          id="CommissionRate"
                          type="number"
                          onChange={(e: any) => {
                            broker.commissionRate = handleNumberInput(e);
                          }}
                          onKeyUp={(e) => {
                            if (
                              appStore.sales.form.soldPrice &&
                              broker.commissionRate
                            ) {
                              broker.commissionAmount = +(
                                (+appStore.sales.form.soldPrice *
                                  broker.commissionRate) /
                                100
                              ).toFixed(0);
                            } else {
                              broker.commissionAmount = undefined;
                            }
                          }}
                          value={broker.commissionRate}
                          startAdornment="%"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor={`commissionamount${i}`}
                          shrink={true}
                        >
                          Commission Amount
                        </InputLabel>
                        <Input
                          id={`commissionamount${i}`}
                          onChange={(e) => {
                            broker.commissionAmount = e.target.value
                              ? +e.target.value
                              : undefined;
                          }}
                          onKeyUp={(e) => {
                            if (
                              appStore.sales.form.soldPrice &&
                              broker.commissionAmount
                            ) {
                              broker.commissionRate = +(
                                (broker.commissionAmount /
                                  +appStore.sales.form.soldPrice) *
                                100
                              ).toFixed(2);
                            } else {
                              broker.commissionRate = undefined;
                            }
                          }}
                          value={broker.commissionAmount}
                          inputComponent={NumberFormatCustom as any}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Broker Position"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) =>
                            (broker.position = e.target.value as string)
                          }
                          value={broker.position}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item sm={12}>
            <Card>
              <CardHeader title={`Costs`}></CardHeader>
              <Divider />
              <CardContent>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => appStore.addCost()}
                >
                  Add Cost
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {appStore.sales.form.costs.map((cost: Cost, i: number) => (
            <Grid item md={4} key={i}>
              <Card style={{ marginTop: 15 }}>
                <CardHeader title={`Cost ${i + 1}`}></CardHeader>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Button onClick={() => appStore.removeCost(i)}>
                        Remove
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Cost Type"
                          onChange={(e) =>
                            (cost.type = e.target.value as string)
                          }
                          value={cost.type}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Responsible Name"
                          onChange={(e) =>
                            (cost.name = e.target.value as string)
                          }
                          value={cost.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="cost-CommissionRate">
                          Commission Rate
                        </InputLabel>
                        <Input
                          id="cost-CommissionRate"
                          type="number"
                          onChange={(e: any) => {
                            cost.commissionRate = handleNumberInput(e);
                          }}
                          onKeyUp={(e) => {
                            if (
                              appStore.sales.form.soldPrice &&
                              cost.commissionRate
                            ) {
                              cost.commissionAmount = +(
                                (+appStore.sales.form.soldPrice *
                                  cost.commissionRate) /
                                100
                              ).toFixed(0);
                            } else {
                              cost.commissionAmount = undefined;
                            }
                          }}
                          value={cost.commissionRate}
                          startAdornment="%"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor={`cost-commissionamount${i}`}
                          shrink={true}
                        >
                          Commission Amount
                        </InputLabel>
                        <Input
                          id={`cost-commissionamount${i}`}
                          onChange={(e) => {
                            cost.commissionAmount = e.target.value
                              ? +e.target.value
                              : undefined;
                          }}
                          onKeyUp={(e) => {
                            if (
                              appStore.sales.form.soldPrice &&
                              cost.commissionAmount
                            ) {
                              cost.commissionRate = +(
                                (cost.commissionAmount /
                                  +appStore.sales.form.soldPrice) *
                                100
                              ).toFixed(2);
                            } else {
                              cost.commissionRate = undefined;
                            }
                          }}
                          value={cost.commissionAmount}
                          inputComponent={NumberFormatCustom as any}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item sm={12}>
            <Card>
              <CardHeader title={`Attachments`}></CardHeader>
              <Divider />
              <CardContent>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => appStore.addAttachment()}
                >
                  Add Attachment
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={12}>
            <Grid container spacing={2}>
              {appStore.sales.form.attachments &&
                appStore.sales.form.attachments.map(
                  (attachment: any, i: number) => (
                    <Grid item md={4} key={i}>
                      <Card style={{ marginTop: 15 }}>
                        <CardHeader title={attachment.name}></CardHeader>
                        <Divider />
                        <CardContent>
                          <a
                            href={`${process.env.REACT_APP_API_URL}${attachment.url}`}
                            target="_blank"
                          >
                            View
                          </a>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                )}
            </Grid>
          </Grid>

          {appStore.sales.files &&
            appStore.sales.files.map(
              (attachment: File | undefined, i: number) => (
                <Grid item md={4} key={i}>
                  <Card style={{ marginTop: 15 }}>
                    <CardHeader title={`New Attachment ${i + 1}`}></CardHeader>
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button onClick={() => appStore.removeAttachment(i)}>
                            Remove
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <input
                            type="file"
                            onChange={(e) =>
                              (appStore.sales.files[i] = e.target.files
                                ? e.target.files[0]
                                : undefined)
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )
            )}
        </Grid>
      </Layout>

      <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
        <DialogTitle>{"Operation Complete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Successfully saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowAlert(false);
              props.history.push("/sales");
            }}
            color="primary"
            autoFocus
          >
            Return to List
          </Button>
          <Button
            onClick={() => {
              setShowAlert(false);
              props.history.push("/sales/edit/" + appStore.sales.form.id);
            }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showDeleteAlert} onClose={() => setShowDeleteAlert(false)}>
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The record will permanently deleted. Do you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDeleteAlert(false);
            }}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setShowDeleteAlert(false);
              const result = await appStore.deleteSale(appStore.sales.form.id);

              if (result) {
                props.history.push("/sales");
              }
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
});

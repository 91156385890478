import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Dashboard } from "./pages/dashboard/Dashboard";
import { Login } from "./pages/auth/Login";
import { SalesListPage } from "./pages/sales/sales.list.page";
import { SalesEditPage } from "./pages/sales/sales.edit.page";
import React from "react";
import { NotFound } from "./pages/error/NotFound";
import { SalesCostPage } from "./pages/sales/sales.costs.page";

const App = () => {
  React.useEffect(() => {
    // load app config
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} component={Dashboard}></Route>
        <Route path="/auth/login" component={Login}></Route>
        <Route path="/sales" exact component={SalesListPage}></Route>
        <Route path="/sales/new" component={SalesEditPage}></Route>
        <Route path="/sales/edit/:id" component={SalesEditPage}></Route>
        <Route path="/sales/costs" component={SalesCostPage}></Route>
        <Route path="*" component={NotFound}></Route>
      </Switch>
    </Router>
  );
};

export default App;
